<template>
  <Header />


  <section style="padding-top:100px; padding-bottom: 100px; background-image: url('/assets/images/people-wearing-mask-workshop-new-normal(1).jpg'); background-size: cover; background-color: rgba(0, 0, 0, 0.7); background-blend-mode: darken; background-position: center; ">
    
    <div class="container">

      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">

          <div style="text-align: center;">
            <h1 class="title-1 text-white font-3"> <span class="color-1"> 49<sup>th</sup> </span> KOGS Annual Scientific <span class="color-1">Congress </span> </h1>
            <h5 class="text-white font-1">17<sup>th</sup> - 21<sup>st</sup> February, 2025</h5>
            <h6 class="text-white font-1">Sarova Whitesands Hotel, Mombasa County</h6>

            <div style="padding-top: 10px;">
              <router-link to="/packages" class="btn btn-2">REGISTER <i class="bi bi-arrow-right"></i> </router-link>
            </div>

          </div>
          
        </div>
        
      </div>
      
    </div>

  </section>




  <section style="padding-top:50px; padding-bottom: 50px; background-color: #013261;">


    <div class="container">

      <div class="row">

        <div class="col-md-2">
          
        </div>


        <div class="col-md-8" style="text-align:center;">
          <h1 class="font-3 text-white"> <b> Theme: </b> </h1>
          <p class="text-white">
                Collaborative partnerships for maternal health : Building the alliances between stakeholders to prevent maternal mortality,share best practices,drive systematic change through innovation
              </p>
        </div>

         <div class="col-md-2">
          
        </div>
        
      </div>
      
    </div>
    
  </section>




  <section style="padding-top:50px; padding-bottom:50px;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-6" style="background-image: url('/assets/images/people-wearing-mask-workshop-new-normal(1).jpg'); background-size:cover; min-height: 300px;"></div>

        <div class="col-md-6" style="padding-top:30px; padding-bottom: 30px; padding-left: 30px; background-color:  #f4faff;">
          <h1 class="font-3 color-3"> <b> Sub-Themes: </b> </h1>
          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Maternal Fetal Medicine Innovations
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Pelvic Health and Rehabilitation
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Policy and Advocacy For Maternal Health
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Data Sharing and Research Collaboration
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Gynaecological Oncology and Maternal Health
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Reproductive Endocrinology Innovations
          </p>


          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Maternity and Quality Improvement
          </p>

          <p>
            <i class="bi bi-arrow-right-circle-fill color-3"></i> Digital Health Solutions and Maternal Health
          </p>

          <div>
            <router-link to="/packages" class="btn btn-1">Register <i class="bi bi-arrow-right"></i> </router-link>
          </div>

        </div>
        
      </div>
      
    </div>

  </section>




  <section style="padding-bottom: 50px;">
    

    <div class="container-fluid">

      <div style="text-align:center; padding-bottom: 30px;">
        <h1 class="font-3 color-2"> <b> Registration Fees </b> </h1>
      </div>

      <div class="row">

        <div class="col-md-12">

          <div class="table-responsive">
            
            <table class="table table-bordered">

              <thead>
                <tr>

                  <th style="text-align:center;">
                    <label class="font-3" style="background-color: #970072; color:#fff; padding:10px; width: 100%;">PACKAGES</label>
                  </th>
                  <th style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  Early Bird<br />
                  (Until Jan,2025)
                </b>
                </h6>
                </th>

                 <th style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  Late Bird<br />
                  (11th Jan - 14th Feb,2025)
                </b>
                </h6>
                </th>


                 <th style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  On Site<br />
                  (Registration) 
                </b>
                </h6>
                </th>

                </tr>
              </thead>


              <tbody>
                
                <tr> 

                   <td style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  MEMBER 
                </b>
                </h6>
                </td>

                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                  KES 30,000
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                  KES 35,000
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                  KES 40,000 
                </b>
                </h6>
                </td>

                </tr>





                <tr> 

                   <td style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  NON-MEMBER 
                </b>
                </h6>
                </td>

                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 45,000 
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 50,000 
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 55,000
                </b>
                </h6>
                </td>

                </tr>





                <tr> 

                   <td style="text-align:center;">
                  <h6 class="color-3" style="font-size: 10px;">
                    <b>
                  ASSOCIATE MEMBER/NURSE/<br />REGISTRAR/CLINICAL OFFICER 
                </b>
                </h6>
                </td>

                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 20,000
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 25,000 
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   KES 30,000
                </b>
                </h6>
                </td>

                </tr>




                <tr> 

                   <td style="text-align:center;">
                  <h6 class="color-3">
                    <b>
                  INTERNATIONAL 
                </b>
                </h6>
                </td>

                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                   USD 450
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                  USD 450
                </b>
                </h6>
                </td>


                 <td style="text-align:center;">
                  <h6 class="color-2">
                    <b>
                  USD 475
                </b>
                </h6>
                </td>

                </tr>





              </tbody>
              
            </table>
            

          </div>
          
        </div>
        
      </div>
      
    </div>

  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  export default{

    name : 'home',
    components : { Header, Footer }

  }

</script>