<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: right;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-2"></div>

        <div class="col-md-8">

          <div style="background-color: #560041; color: #fff; padding: 10px;">
            <i class="bi bi-info-circle"></i> Membership :   {{this.$store.state.membership}}
          </div>

          <div style="border: solid 1px #ddd; padding: 15px; background-color: #fff;" class="shadow-lg">

          <form @submit.prevent="register()">
            
            <div class="row">

              <div class="col-md-6 mt-1">
                 <label>Title <span class="color-3">*</span> </label>
            <select class="form-select input-design mt-1" v-model="title">
              <option>Prof</option>
              <option>Dr</option>
              <option>Mr</option>
              <option>Mrs</option>
              <option>Ms</option>
            </select>
              </div>


              <div class="col-md-6 mt-1">
                 <label>First name <span class="color-3">*</span></label>
            <input class="form-control input-design mt-1" placeholder="Enter first name" v-model="first_name">
              </div>
 
              <div class="col-md-6 mt-1">
                 <label>Middle name</label>
            <input class="form-control input-design mt-1" placeholder="Enter middle name" v-model="middle_name">
              </div>

              <div class="col-md-6 mt-1">
                 <label>Surname <span class="color-3">*</span></label>
            <input class="form-control input-design mt-1" placeholder="Enter surname" v-model="surname">
              </div>


              <div class="col-md-6 mt-1">
                 <label>KMPDC number(optional)</label>
            <input class="form-control input-design mt-1" placeholder="Enter KMPDC number" v-model="kmpdc">
              </div>


              <div class="col-md-6 mt-1">
                 <label>Area of specialization <span class="color-3">*</span></label>
            <select class="form-select input-design mt-1" v-model="specialization">
                        <option>Adolescent Health and Sexuality</option>
    <option>Family Planning and Contraception</option>
    <option>Fertility and Endocrinology</option>
    <option>Gynecologic Oncology</option>
    <option>Health Policy and Implementation</option>
    <option>Science Information Technology in health</option>
    <option>Maternal-fetal Medicine</option>
    <option>Urogynecology</option>
    <option>Minimal access Surgery</option>
    <option>Other</option>
            </select>
              </div>

              <div class="col-md-6 mt-1">
                 <label>Email address <span class="color-3">*</span></label>
            <input type="email" class="form-control input-design mt-1" placeholder="Enter email address" v-model="email">
              </div>

              <div class="col-md-6 mt-1">
                 <label>Phone number <span class="color-3">*</span></label>
            <input class="form-control input-design mt-1" placeholder="Enter phone number" v-model="phone">
              </div>


              <div class="col-md-6 mt-1">
                 <label>Choose branch(optional)</label>
            <select class="form-select input-design mt-1" v-model="branch">
              <option>Nairobi</option>
     <option>Coast</option>
     <option>Central</option>
     <option>Northern</option>
     <option>Nyanza</option>
     <option>Northrift</option>
     <option>Southrift</option>
     <option>Western</option>
     <option>Eastern</option>
            </select>
              </div>


              <div class="col-md-6 mt-1">
                 <label>Country <span class="color-3">*</span></label>
            <input class="form-control input-design mt-1" placeholder="Enter country" v-model="country">
              </div>


              <div class="col-md-6 mt-1">
                 <label>Town <span class="color-3">*</span></label>
            <input class="form-control input-design mt-1" placeholder="Enter town" v-model="town">
              </div>

              <div class="col-md-6 mt-1">
                 <label>Postal address(optional)</label>
            <input class="form-control input-design mt-1" placeholder="Enter phone number" v-model="postal_address">
              </div>

              <div class="col-md-6 mt-1">
                 <label>Postal code(optional)</label>
            <input class="form-control input-design mt-1" placeholder="Enter postal code" v-model="postal_code">
              </div>

               <div class="col-md-6 mt-1">
                 <label>Payment method <span class="color-3">*</span></label>
            <select class="form-select input-design mt-1" v-model="payment_method">
              <option v-if="this.$store.state.membership!='International'">MPESA</option>
             <option>Bank Transfer</option>
    
            </select>
              </div>

               <div style="padding-top:10px;" class="col-md-12">
              <button class="btn btn-1 w-100" :disabled="disabled"> {{text}} <i class="bi bi-arrow-right"></i> </button>
            </div>
              
            </div>

          </form>  

           
            
          </div>
          
        </div>
        
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  import axios from 'axios'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
        title : '',
        first_name : '',
        middle_name : '',
        surname : '',
        kmpdc : '',
        specialization : '',
        email : '',
        phone : '',
        branch : '',
        country : '',
        town : '',
        postal_address : '',
        postal_code : '',
        payment_method : '',
        text : 'Next',
        disabled : false
      }
    },

    methods : {


      async mpesa(){

        this.text = 'Please Wait...'
        this.disabled = true 

        const res = await axios.post(this.$store.state.url+'api/mpesa',{

        phone : this.$store.state.phone,
        amount : this.$store.state.amount,
        ref : this.$store.state.email,
        desc : 'Paying for 49th KOGS Annual Scientific Congress',

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

         this.$router.push('/mpesa')


         this.text = 'Next'
        this.disabled = false 


      },

      async register_now(){

        this.text = 'Please Wait...'
        this.disabled = true 

        const res = await axios.post(this.$store.state.url+'api/register',{

        title : this.title,
        first_name : this.first_name,
        middle_name : this.middle_name,
        surname : this.surname,
        specialization : this.specialization,
        branch : this.branch,
        kmpdc : this.kmpdc,
        email : this.email,
        phone : this.phone,
        country : this.country,
        town : this.town,
        postal_address : this.postal_address,
        postal_code : this.postal_code,
        payment_method : this.payment_method,
        amount : this.$store.state.amount,
        membership : this.$store.state.membership

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res=='success'){
          this.$router.push('/bank-transfer')
        }else{

            Swal.fire({
  title: "Error",
  text: res,
  icon: "error"
});

        }


         this.text = 'Next'
        this.disabled = false 


      },



      register(){

        if(!this.title){
          Swal.fire({
  title: "Error",
  text: "Choose title",
  icon: "error"
});
          return
        }


         if(!this.first_name){
          Swal.fire({
  title: "Error",
  text: "Enter first name",
  icon: "error"
});
          return
        }


         if(!this.surname){
          Swal.fire({
  title: "Error",
  text: "Enter surname",
  icon: "error"
});
          return
        }

         if(!this.specialization){
          Swal.fire({
  title: "Error",
  text: "Choose specialization",
  icon: "error"
});
          return
        }

         if(!this.email){
          Swal.fire({
  title: "Error",
  text: "Enter email address",
  icon: "error"
});
          return
        }


         if(!this.phone){
          Swal.fire({
  title: "Error",
  text: "Enter phone number",
  icon: "error"
});
          return
        }


       if(!this.country){
          Swal.fire({
  title: "Error",
  text: "Enter country",
  icon: "error"
});
          return
        }


        if(!this.town){
          Swal.fire({
  title: "Error",
  text: "Enter town",
  icon: "error"
});
          return
        }


        if(!this.payment_method){
          Swal.fire({
  title: "Error",
  text: "Choose payment method",
  icon: "error"
});
          return
        }


        this.$store.state.title = this.title
        this.$store.state.first_name = this.first_name
        this.$store.state.middle_name = this.middle_name
        this.$store.state.surname = this.surname
        this.$store.state.specialization = this.specialization
        this.$store.state.branch = this.branch
        this.$store.state.kmpdc = this.kmpdc
        this.$store.state.email = this.email
        this.$store.state.phone = this.phone
        this.$store.state.country = this.country
        this.$store.state.town = this.town
        this.$store.state.postal_address = this.postal_address
        this.$store.state.postal_code = this.postal_code
        this.$store.state.payment_method = this.payment_method

        if(this.payment_method=='Bank Transfer'){

          this.register_now()

        }


        if(this.payment_method=='MPESA'){

         this.mpesa()

        }



      }
    }

  }

</script>