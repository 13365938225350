import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import Packages from '../components/Packages'
import Register from '../components/Register'
import BankTransfer from '../components/BankTransfer'
import Mpesa from '../components/Mpesa'
import Success from '../components/Success'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

   {
    path: '/packages',
    name: 'packages',
    component: Packages
  },

   {
    path: '/register',
    name: 'register',
    component: Register
  },

   {
    path: '/bank-transfer',
    name: 'bank-transfer',
    component: BankTransfer
  },

   {
    path: '/mpesa',
    name: 'mpesa',
    component: Mpesa
  },

   {
    path: '/success',
    name: 'success',
    component: Success
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
