<template>
	
	

  <section style="background-color: #001121; padding-top: 100px; padding-bottom: 50px;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-3" style="text-align:left;">
          <img src="/assets/images/logo.png" class="logo" style="background-color:#fff; border-radius: 100%;"> 
          <h6 class="text-white mt-3 font-3"> 49th KOGS Annual Scientific Congress </h6>
        </div>

        <div class="col-md-3">
          <h6 class="text-white font-3">Quick Links</h6>
          <router-link to="/" class="btn text-white p-0">Home</router-link> <br />
          <router-link to="/" class="btn text-white p-0">About</router-link> <br />
          <router-link to="/" class="btn text-white p-0">Speakers</router-link> <br />
          <router-link to="/" class="btn text-white p-0">Venue & Accommodation</router-link>

        </div>


         <div class="col-md-3">
          <h6 class="text-white font-3">Contact Us</h6>
          <router-link to="/" class="btn text-white p-0"> <i class="bi bi-envelope"></i> conference@kogs.or.ke </router-link> <br />
          <router-link to="/" class="btn text-white p-0"> <i class="bi bi-telephone"></i> +254 726 639621 </router-link> <br />
          <router-link to="/" class="btn text-white p-0">  <i class="bi bi-globe"></i> www.kogs.or.ke </router-link> <br />
          

        </div>



        <div class="col-md-3">
          <h6 class="text-white font-3">Social Media</h6>
          <router-link to="/" class="btn text-white p-0"> <i class="bi bi-instagram"></i> @kogsociety </router-link> <br />
          <router-link to="/" class="btn text-white p-0"> <i class="bi bi-facebook"></i> @kogsociety </router-link> <br />
          <router-link to="/" class="btn text-white p-0">  <i class="bi bi-twitter-x"></i> TheKOGSociety </router-link> <br />
          

        </div>


        
      </div>
      
    </div>


  </section>

  <section style="text-align:center; background-color: #000; color: #fff; padding: 6px;">
    
<h6 class="font-3">Copyright © 2024 KOGS</h6>

  </section>



</template>