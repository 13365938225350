<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: right;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-2"></div>

        <div class="col-md-8">

          <div style="background-color: #560041; color: #fff; padding: 10px;">
            <i class="bi bi-info-circle"></i> Payment Method :   {{this.$store.state.payment_method}}
          </div>

          <div style="border: solid 1px #ddd; padding: 15px; background-color: #fff; text-align: center; " class="shadow-lg">
            <h1> <i class="bi bi-info-circle color-3"></i> </h1>
            <p> <i class="bi bi-check color-3"></i> A prompt has been sent to your phone<b> ({{this.$store.state.phone}}) </b> </p>

            <p> <i class="bi bi-check color-3"></i> Enter your <b>MPESA</b> PIN to complete payment </p>  

            <div v-if="payment_received">
              <h6 class="text-success"><i class="bi bi-check-circle"></i> Payment received!</h6>
              <h6 class="text-success"><i class="bi bi-check-circle"></i> Completing registration...</h6>
            </div>


            <div v-if="!payment_received">
              
               <span class="spinner-border text-danger" role="status">
  
          </span><br />
           Waiting you to enter <b>PIN...</b>          

           <div style="padding-top:10px;">
             <span class="color-2">Didn't get the prompt?</span> <br /> <button @click="mpesa()" class="btn btn-1 mt-1" :disabled="disabled">{{text}}</button>
           </div>

            </div>

           
       
            
          </div>
          
        </div>
        
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  import axios from 'axios'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
         text : 'Request Again',
        disabled : false,
        payment_received : false,
        x : '',
        code : ''
        
      }
    },

    methods : {


      async register_now(){

        const res = await axios.post(this.$store.state.url+'api/register',{

        title : this.$store.state.title,
        first_name : this.$store.state.first_name,
        middle_name : this.$store.state.middle_name,
        surname : this.$store.state.surname,
        specialization : this.$store.state.specialization,
        branch : this.$store.state.branch,
        kmpdc : this.$store.state.kmpdc,
        email : this.$store.state.email,
        phone : this.$store.state.phone,
        country : this.$store.state.country,
        town : this.$store.state.town,
        postal_address : this.$store.state.postal_address,
        postal_code : this.$store.state.postal_code,
        payment_method : this.$store.state.payment_method,
        amount : this.$store.state.amount,
        membership : this.$store.state.membership

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

       


      },


      async update_code(){
  
        const res = await axios.post(this.$store.state.url+'api/update-code',{

        code : this.code,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

      },



      async check_payment(){
  
        const res = await axios.post(this.$store.state.url+'api/check-payment',{

        phone : this.$store.state.phone,
        amount : this.$store.state.amount,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res.length>0){

          //get payment details
          res.forEach((data)=>{
            this.code = data.code;
          })

          this.payment_received = true
          this.stop_timer()
          this.register_now()
          this.update_code()
          this.$router.push('/success')
        }else{
          this.payment_received = false
        }


      },



      async mpesa(){
    
    this.text = 'Please Wait...'
    this.disabled = true

        const res = await axios.post(this.$store.state.url+'api/mpesa',{

        phone : this.$store.state.phone,
        amount : this.$store.state.amount,
        ref : this.$store.state.email,
        desc : 'Paying for 49th KOGS Annual Scientific Congress',

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })


    this.text = 'Request Again'
    this.disabled = false

      },

      start_timer(){

        this.x = setInterval(()=>{
          this.check_payment()
          console.log('checking...')
        },5000)

      },

      stop_timer(){
        clearInterval(this.x)
      }




    },

    mounted(){

      this.start_timer()

    }

  }

</script>