<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: right;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-2"></div>

        <div class="col-md-8">

          <div style="background-color: #560041; color: #fff; padding: 10px;">
            <i class="bi bi-info-circle"></i> Payment Method :   {{this.$store.state.payment_method}}
          </div>

          <div style="border: solid 1px #ddd; padding: 15px; background-color: #fff; text-align: center; " class="shadow-lg">

            <h1> <i class="bi bi-check-circle text-success"></i>  </h1>
            <h3 class="color-3"> <b> Success </b> </h3>
            <p class="color-2">You have successfully registered for the  <b>49th KOGS Annual Scientific Congress</b> </p>
                     


            <router-link to="/" class="btn btn-1"> <i class="bi bi-x"></i> Close</router-link>
       
            
          </div>
          
        </div>
        
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
        
      }
    },

    methods : {

    }

  }

</script>