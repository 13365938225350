<template>

  <div style="text-align: center; background-color: #023066; color: #fff; padding-top: 5px; padding-bottom: 1px;">
   <h6 class="font-3"> 100 Days,15 Hours Remaining</h6>
  </div>
	
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <router-link to="/" class="navbar-brand">
    	<img src="/assets/images/logo.png" class="logo">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        
        <li class="nav-item">
          <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/">About</router-link>
        </li>

         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/">Program</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/">Speakers</router-link>
        </li>


         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/">Venue & Accommodation</router-link>
        </li>





         <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/">Contact Us</router-link>
        </li>

       


  
      </ul>
      <div class="d-flex">
       
        <router-link to="/packages" class="btn btn-1">Register <i class="bi bi-arrow-right"></i> </router-link>
      </div>
    </div>
  </div>
</nav>

</template>