<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-4"></div>

        <div class="col-md-4">

          <div style="background-color: #560041; color: #fff; padding: 10px;">
            <i class="bi bi-info-circle"></i>  Amount to pay : {{this.$store.state.currency}}.{{this.$store.state.amount}}
          </div>

          <div style="border: solid 1px #ddd; padding: 15px; background-color: #fff;" class="shadow-lg">

            <label>Choose Membership</label>
            <select class="form-select input-design mt-2" v-model="membership" @change="get_amount()">
              <option>Member</option>
              <option>Associate Member/Nurse/Registrar/Clinical Officer</option>
              <option>Non-Member</option>
              <option>International</option>
            </select>


            

            <div style="padding-top:10px;">
              <button @click="next()" class="btn btn-1 w-100">Next <i class="bi bi-arrow-right"></i> </button>
            </div>
            
          </div>
          
        </div>
        
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
        membership : this.$store.state.membership
      }
    },

    methods : {

      get_amount(){

        if(this.membership=='Member'){
          this.$store.state.amount = 30000
        }


        if(this.membership=='Non-Member'){
          this.$store.state.amount = 45000
        }


        if(this.membership=='Associate Member/Nurse/Registrar/Clinical Officer'){
          this.$store.state.amount = 20000
        }

        if(this.membership=='International'){
          this.$store.state.amount = 450
        }

        if(this.membership=='International'){
          this.$store.state.currency = 'USD'
        }else{
          this.$store.state.currency = 'Ksh'
        }

      },

      next(){

        if(!this.membership){
          Swal.fire({
  title: "Error",
  text: "Choose membership",
  icon: "error"
});
          return
        }

        this.$store.state.membership = this.membership

        this.$router.push('/register')

      }
    }

  }

</script>